@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@400;500;700&display=swap');
body {
  font-family: 'Noto Sans Armenian', sans-serif;
  overflow-y: scroll;
  overflow-x: hidden;
}
button:active,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.form-control:active,
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #324263;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
html,
body,
#root {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
}
main {
  flex: 1;
}
